<template>
    <div>
        <el-dialog
        width="60%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
       
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate">
                    <div class="title1"><h3>请填写商家信息</h3></div>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="关联用户" prop="userId" label-width="80px">
                                <template>
                                    <el-select v-model="formModel.userId" filterable placeholder="请选择关联商家人员" style="width:300px"  @change="handleSelect">
                                        <el-option
                                        v-for="result in userList"
                                        :key="result.id"
                                        :label="result.realName"
                                        :value="result.id"
                                       >
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="商家类型" prop="type" label-width="80px">
                                <template>
                                    <el-select v-model="formModel.type" filterable placeholder="请选择关联商家人员" style="width:300px"  @change="handleSelect">
                                        <el-option
                                        v-for="result in typeList"
                                        :key="result.value"
                                        :label="result.name"
                                        :value="result.value"
                                       >
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商家名称" prop="merchantName" label-width="80px">
                                <el-input v-model="formModel.merchantName" placeholder="请输入商家名称" style="width:300px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系人" prop="contactName" label-width="80px">
                                <el-input v-model="formModel.contactName" placeholder="请输入联系人" style="width:300px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="联系方式" prop="contactPhone" label-width="80px">
                                <el-input v-model="formModel.contactPhone" placeholder="请输入联系方式" style="width:300px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="地址" prop="address" label-width="80px">
                                <el-input
                                    type="textarea"
                                    :rows="5"
                                    placeholder="请输入地址"
                                    style="width:300px"
                                    v-model="formModel.address">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class="title2"><h3>收款账户信息</h3></div>
                    <div class="user-panel" v-loading="loading">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="开户名称" prop="accountName" label-width="80px">
                                    <el-input v-model="formModel.accountName" placeholder="请输入开户名称" style="width:300px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="开户银行" prop="accountBank" label-width="80px">
                                    <el-input v-model="formModel.accountBank" placeholder="请输入开户银行" style="width:300px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="账号" prop="accountNumber" label-width="80px">
                                    <el-input v-model="formModel.accountNumber" placeholder="请输入账号号码" style="width:300px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联行号" prop="interBankNumber" label-width="80px">
                                    <el-input v-model="formModel.interBankNumber" placeholder="请输入联行号" style="width:300px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="金额冻结时间(天)" prop="amountFreezeDays" label-width="80px">
                                    <el-input v-model="formModel.amountFreezeDays" placeholder="请输入金额冻结时间" style="width:300px" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>

        </el-dialog>
    </div>
</template>
<script>
import merchantInfoApi from '@/api/merchant/merchantInfo'
export default {
    props: ["businessKey", "title"],
    data() {
        return{
            formModel:{
                id: "",
                userName:"",
                password:"",
                merchantName:"",
                contactName:"",
                contactPhone:"",
                address:"",
                accountName:"",
                accountBank:"",
                accountNumber:"",
                interBankNumber:"",
                type:"1",
                amountFreezeDays:"",
                
            },
            ruleValidate: {
                userId: [{ required: true, message: "关联商家人员不能为空", trigger: "blur" }],
                merchantName: [{ required: true, message: "商家名称不能为空", trigger: "blur" }],
                contactName: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
                contactPhone: [{ required: true, message: "联系方式不能为空", trigger: "blur" }],
                amountFreezeDays: [{ required: true, message: "金额冻结时间不能为空", trigger: "blur" }]
            },
            userList:[],
            typeList:[{
                name:"商户",
                value:"1"
            },{
                name:"学校",
                value:"2"
            },{
                name:"销售人员",
                value:"3"
            }],
            showDialog: true,
            loading: false,
            submitting: false,
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSelect(selVal){
            var id = this.formModel.id;

            var formData = new FormData();

            formData.append("id",id);
            formData.append("selectId",selVal);

            merchantInfoApi.validateUser(formData).then(response => {
                var jsonData = response.data;
                var flag = jsonData.data;
                if(!flag){
                    this.$message.error("人员已经绑定过其他商家，请重新选择！");
                    this.formModel.userId="";
                }
            });

        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;
                    
                    if (id == null || id.length == 0) {
                        return merchantInfoApi.add(self.formModel);
                    } else {
                        return merchantInfoApi.update(self.formModel);
                    }
                    
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });
                    self.$emit("close", {
                        result: true,
                        data: jsonData.data
                    });
                    } else {
                        self.$message({
                            message: jsonData.message + "",
                            type: "warning"
                        });

                        self.$emit("close", {
                            result: false
                        });
                    }
                });
                }
            });
        },

    },
    created() {
        var self = this;

        var formData = new FormData();


        merchantInfoApi.userList().then(response => {
            var jsonData = response.data;
            this.userList = jsonData.data;
        });
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            if (self.businessKey.length == 0) {
                return merchantInfoApi.create();
            } else {
                return merchantInfoApi.edit(self.businessKey);
            }
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
            self.formModel = jsonData.data;
            } else {
            self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>
.title1{
    margin-bottom: 40px;
}
.title2{
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>