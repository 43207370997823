<template>
    <div class="merchantInfo-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">商家管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="商家名称" prop="merchantName">
                <el-input v-model="queryModel.merchantName" size="mini"/>
            </el-form-item>
            <el-form-item label="商家类型" prop="type">
                <el-select v-model="queryModel.type" filterable size="mini" >
                    <el-option
                    v-for="result in typeList"
                    :key="result.value"
                    :label="result.name"
                    :value="result.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="联系方式" prop="phone">
                <el-input v-model="queryModel.phone" size="mini"/>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-circle-plus"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
        >删除选中项</el-button>
        </el-row>
                <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="merchantName" label="商家名称"></el-table-column>
            <el-table-column prop="typeStr" label="商户类型"></el-table-column>
            <el-table-column prop="contactName" label="联系人姓名"></el-table-column>
            <el-table-column prop="contactPhone" label="联系方式"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="accountName" label="开户名称"></el-table-column>
            <el-table-column prop="accountBank" label="开户银行"></el-table-column>
            <el-table-column prop="accountNumber" label="卡号"></el-table-column>
            <el-table-column prop="interBankNumber" label="联行号"></el-table-column>
            <el-table-column prop="amountFreezeDays" label="金额冻结时间"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <merchantInfo-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        @close="onDetailModalClose"
        ></merchantInfo-detail>
    </div>
</template>
<script>
import merchantInfoApi from '@/api/merchant/merchantInfo'
import merchantInfoDetail from "./merchantInfo-detail";
export default {
    name:"merchantMerchantInfoList",
    data(){
         return{
            queryModel:{
                merchantName:"",
                type:"",
                phone:"",
            },
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
            typeList:[{
                name:"商户",
                value:"1"
            },{
                name:"学校",
                value:"2"
            },{
                name:"销售人员",
                value:"3"
            }],
         }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("merchantName", this.queryModel.merchantName);
            formData.append("type", this.queryModel.type);
            formData.append("phone", this.queryModel.phone);


            merchantInfoApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleAdd() {
        this.modalTitle = "新增";
        this.businessKey = "";
        this.showModal = true;
        },
        handleEdit(record) {
                this.modalTitle = "编辑";
                this.operation = "edit";
                this.businessKey = record.id;
                this.selectedRecord = record;
                this.showModal = true;
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    list.splice(i, 1);
                    rs = true;
                    break;
                }

                if (list[i].children != null) {
                    rs = this.loopDelete(list[i].children, id);

                    if (rs) {
                        break;
                    }
                }
            }

            return rs;
        },
        handleDelete(record) {
            var self = this;

            self
                .$confirm("是否确认删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    merchantInfoApi.remove(record.id).then(function (response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            var rs = self.loopDelete(self.tableData, record.id);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
        },
        handleBatchDelete() {
                var self = this;

                var idList = this.multipleSelection.map(record => {
                    return record.id;
                });

                this.$confirm("是否确认删除选中项？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    merchantInfoApi.batchRemove(idList).then(function (response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.changePage(self.pageIndex);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
            },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;

            if (refreshed) {
                this.changePage(this.pageIndex);
            }
        },
    },
    components: {
        "merchantInfo-detail": merchantInfoDetail,
    },
    mounted() {
        this.changePage(1);
    },
}
</script>
<style scoped>
.merchantInfo-list{
    text-align: left;
}
.el-breadcrumb {
    margin: 10px;
    line-height: 20px;
}

.el-divider {
    margin: 5px 0;
}
</style>