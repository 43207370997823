import request from '@/utils/request'
import constant from '@/constant'

function edit(id){
    return request.get(constant.serverUrl + "/merchant/merchantInfo/edit/" + id);
}

function create(){
  return request.get(constant.serverUrl + "/merchant/merchantInfo/create");
}

function pageList(formData){
    return request.post(constant.serverUrl + "/merchant/merchantInfo/pageList", formData);
}

function add(formModel){
    return request.post(constant.serverUrl + "/merchant/merchantInfo/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}

function update(formModel){  
    return request.post(constant.serverUrl + "/merchant/merchantInfo/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function remove(id){
  return request.post(constant.serverUrl + "/merchant/merchantInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/merchant/merchantInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}
function list(formData) {
  return request.post(constant.serverUrl + "/merchant/merchantInfo/list", formData);
}

function userList() {
  return request.post(constant.serverUrl + "/merchant/merchantInfo/userList");
}

function validateUser(formModel) {
  return request.post(constant.serverUrl + "/merchant/merchantInfo/validateUser",formModel);
}




export default {
    pageList,edit,create,add,update,remove,batchRemove,list,userList,validateUser
}